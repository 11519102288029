import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { parseDateToString } from '@/auth/utils';
import studentStoreModule from '../studentStoreModule';

export default function useStudentModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'student';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, studentStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    let data = {
      name: itemLocal.value.name,
      refId: itemLocal.value.refId,
      contactPhone: itemLocal.value.contactPhone,
      contactEmail: itemLocal.value.contactEmail,
      fatherName: itemLocal.value.fatherName,
      motherName: itemLocal.value.motherName,
      schoolId: itemLocal.value.school ? itemLocal.value.school.id : null,
      classId: itemLocal.value.class ? itemLocal.value.class.id : null,
      tuitionFee: itemLocal.value.tuitionFee,
      hourPerDay: itemLocal.value.hourPerDay,
      classTime: itemLocal.value.classTime,
      timeTable: itemLocal.value.timeTable,
      note: itemLocal.value.note,
      active: itemLocal.value.active ?? false,
    };

    const startDate = parseDateToString(itemLocal.value.startDate);
    if (startDate) {
      data = {
        ...data,
        startDate,
      };
    }

    isSubmitting.value = true;

    if (itemLocal.value.id && itemLocal.value.id > 0) {
      store
        .dispatch('student/updateStudent', { data, id: itemLocal.value.id })
        .then(response => { emit('on-item-updated', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      store
        .dispatch('student/createStudent', data)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  return {
    refModal,
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    onSubmit,
  };
}
