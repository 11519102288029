<template>
  <b-modal
    id="modal-student-detail"
    ref="refModalStudentDetail"
    title="Thông tin học sinh"
    size="lg"
    scrollable
    :hide-footer="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
  >

    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        v-if="isLoading"
        class="text-center"
        variant="secondary"
      />
    </div>

    <div v-if="itemLocal && !isLoading">
      <b-list-group class="">
        
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Họ tên:
            </b-col>
            <b-col class="">
              {{ itemLocal.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.refId">
          <b-row>
            <b-col class="font-weight-bolder">
              Mã định danh:
            </b-col>
            <b-col class="">
              {{ itemLocal.refId }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.fatherName">
          <b-row>
            <b-col class="font-weight-bolder">
              Họ tên bố:
            </b-col>
            <b-col class="">
              {{ itemLocal.fatherName }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.motherName">
          <b-row>
            <b-col class="font-weight-bolder">
              Họ tên mẹ:
            </b-col>
            <b-col class="">
              {{ itemLocal.motherName }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.contactPhone">
          <b-row>
            <b-col class="font-weight-bolder">
              Số điện thoại liên hệ:
            </b-col>
            <b-col class="">
              {{ itemLocal.contactPhone }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.contactEmail">
          <b-row>
            <b-col class="font-weight-bolder">
              Email liên hệ:
            </b-col>
            <b-col class="">
              {{ itemLocal.contactEmail }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.tuitionFee">
          <b-row>
            <b-col class="font-weight-bolder">
              Học phí:
            </b-col>
            <b-col class="">
              {{ Number(itemLocal.tuitionFee).toLocaleString() }} đ
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.hourPerDay">
          <b-row>
            <b-col class="font-weight-bolder">
              Thời lượng học:
            </b-col>
            <b-col class="">
              {{ Number(itemLocal.hourPerDay).toLocaleString() }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.classTime">
          <b-row>
            <b-col class="font-weight-bolder">
              Hình thức học:
            </b-col>
            <b-col class="">
              {{ itemLocal.classTime }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.timeTable">
          <b-row>
            <b-col class="font-weight-bolder">
              Lịch học:
            </b-col>
            <b-col class="">
              {{ itemLocal.timeTable }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.startDate">
          <b-row>
            <b-col class="font-weight-bolder">
              Ngày bắt đầu học:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.startDate) }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.note">
          <b-row>
            <b-col class="font-weight-bolder">
              Ghi chú:
            </b-col>
            <b-col class="">
              <span style="white-space: pre-line;">{{ itemLocal.note }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.school">
          <b-row>
            <b-col class="font-weight-bolder">
              Cơ sở:
            </b-col>
            <b-col class="">
              {{ itemLocal.school.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.class">
          <b-row>
            <b-col class="font-weight-bolder">
              Lớp:
            </b-col>
            <b-col class="">
              {{ itemLocal.class.name }}
            </b-col>
          </b-row>
        </b-list-group-item>

      </b-list-group>

    </div>
    <!-- Body -->

  </b-modal>
</template>

<script>
import {
  BModal,
  BSpinner,
  BCol,
  BRow,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import AvatarView from '@/views/components/AvatarView.vue';
import useStudentDetailModal from './useStudentDetailModal';

export default {
  components: {
    BModal,
    BSpinner,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,

    AvatarView,
  },
  props: {
    studentId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      onOpen,
      isLoading,
      parseDateToString
    } = useStudentDetailModal(props, emit, refFormObserver);

    return {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onOpen,
      isLoading,
      parseDateToString
    };
  },
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .vgt-wrap {
    .vgt-table {
      thead {
        th {
          font-size: 1rem !important;
          font-weight: 500;
        }
      }
      td {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
  }
  </style>
