<template>
  <b-modal
    id="modal-student"
    ref="refModal"
    title="Học sinh"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        
        
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group
                label="Tên học sinh"
              >
                <template
                  v-slot:label
                >
                Tên học sinh
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="name"
                  v-model="itemLocal.name"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="refId"
              rules=""
            >
              <b-form-group
                label="Mã định danh"
              >
                <template
                  v-slot:label
                >
                Mã định danh
                </template>
                <b-form-input
                  id="refId"
                  v-model="itemLocal.refId"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="fatherName"
              rules=""
            >
              <b-form-group
                label="Họ tên bố"
              >
                <template
                  v-slot:label
                >
                  Họ tên bố
                </template>
                <b-form-input
                  id="fatherName"
                  v-model="itemLocal.fatherName"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="motherName"
              rules=""
            >
              <b-form-group
                label="Họ tên mẹ"
              >
                <template
                  v-slot:label
                >
                Họ tên mẹ
                </template>
                <b-form-input
                  id="motherName"
                  v-model="itemLocal.motherName"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="contactPhone"
              rules="required"
            >
              <b-form-group
                label="Điện thoại liên lạc"
              >
                <template
                  v-slot:label
                >
                Điện thoại liên lạc
                <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="contactPhone"
                  v-model="itemLocal.contactPhone"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="contactEmail"
              rules="email"
            >
              <b-form-group
                label="Email liên lạc"
              >
                <template
                  v-slot:label
                >
                Email liên lạc
                </template>
                <b-form-input
                  id="contactEmail"
                  v-model="itemLocal.contactEmail"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="classTime"
              rules=""
            >
              <b-form-group
                label="Hình thức học"
              >
                <template
                  v-slot:label
                >
                Hình thức học
                </template>
                <b-form-input
                  id="classTime"
                  v-model="itemLocal.classTime"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="timeTable"
              rules=""
            >
              <b-form-group
                label="Lịch học"
              >
                <template
                  v-slot:label
                >
                Lịch học
                </template>
                <b-form-input
                  id="timeTable"
                  v-model="itemLocal.timeTable"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="hourPerDay"
              rules=""
            >
              <number-input
                v-model="itemLocal.hourPerDay"
                :default-value="itemLocal.hourPerDay"
                label="Thời lượng học"
                placeholder="1"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="tuitionFee"
              rules=""
            >
              <number-input
                v-model="itemLocal.tuitionFee"
                :default-value="itemLocal.tuitionFee"
                label="Học phí"
                placeholder="1"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="startDate"
                rules=""
              >
                <select-date
                  v-model="itemLocal.startDate"
                  :default-value="itemLocal.startDate"
                  label="Ngày bắt đầu học"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>

            </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="school"
              rules="required"
            >
              <select-school
                v-model="itemLocal.school"
                required
                :default-value="itemLocal.school"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="class"
              rules="required"
            >
              <select-class
                label="Lớp học"
                v-model="itemLocal.class"
                required
                :default-value="itemLocal.class"
                :school="itemLocal.school"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
                    <!-- Active/Inactive -->
                    <b-col
            cols="12"
            class="mt-2"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="itemLocal.active"
                name="check-button"
                switch
                inline
              >
                {{ itemLocal.active ? 'Đang học' : 'Đã thôi học' }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          </b-row>
          <b-row>
          <b-col cols="12">
            <b-form-group label="Ghi chú">
              <b-form-textarea
                id="note"
                v-model="itemLocal.note"
                placeholder="Ghi chú"
                rows="3"
              />
            </b-form-group>

          </b-col>

        </b-row>
          
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
  BFormTextarea
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import ImageView from '@/views/components/ImageView.vue';
import useStudentModal from './useStudentModal';
import SelectSchool from '../../components/SelectSchool.vue';
import SelectClass from '../../components/SelectClass.vue';
import NumberInput from '@/views/components/NumberInput.vue';
import SelectDate from '@/views/components/SelectDate.vue';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormTextarea,
    ImageView,
    SelectSchool,
    SelectClass,
    NumberInput,
    SelectDate
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,

      onSubmit,
    } = useStudentModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
